import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import title from "./title.svg";

const Wrapper = styled(Box)(({ theme }) => ({
  userSelect: "none",
  img: {
    width: "100%",
  },
}));

const Title = () => {
  return (
    <Wrapper>
      <img src={title} alt="Leaderboards" />
    </Wrapper>
  );
};

export default Title;
