import * as types from "./actionTypes";
import produce from "immer";

const initialState = {
  loading: true,
  data: {},
};

export const playersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.PLAYER_FETCH_PLAYER:
        draft.activePlayerName = action.name;
        break;

      case types.PLAYER_FETCH_PLAYER_STARTED:
        draft.loading = true;
        draft.failed = false;
        break;

      case types.PLAYER_FETCH_PLAYER_SUCCEEDED: {
        draft.loading = false;
        const key = action.data.publicName;
        if (key) {
          draft.data[key] = action.data;
        }
        break;
      }

      case types.PLAYER_FETCH_PLAYER_FAILED:
        draft.loading = false;
        draft.failed = true;
        break;

      default:
        break;
    }
  });

export default playersReducer;
