export const mapObjKeys = (data, keyMapping) => {
  if (!data || !keyMapping) {
    return {};
  }

  const result = {};

  Object.keys(data).forEach((key) => {
    if (key in keyMapping) {
      result[keyMapping[key]] = data[key];
    }
  });

  return result;
};

export const createPlayerPageUrl = (name) => `/player/${name}`;

export const displayPercent = (x) => {
  return Math.round(x * 100 * 1000) / 1000 + "%";
};
