import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Logo from "components/atoms/Logo";
import React from "react";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Nav = () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
};

export default Nav;
