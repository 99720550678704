import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { fonts } from "theme/fonts";

const Wrapper = styled(Stack)(({ color }) => ({
  background: "#fff",
  alignItems: "center",
  justifyContent: "center",
  width: 44,
  alignSelf: "stretch",
  borderLeft: `4px solid ${color}`,
}));

const Rank = ({ rank, color }) => {
  return (
    <Wrapper color={color}>
      <Typography
        variant="span"
        fontSize={20}
        fontFamily={fonts.monospace}
        fontWeight={700}
        color={color}
        sx={{ letterSpacing: -1 }}
      >
        {rank}
      </Typography>
    </Wrapper>
  );
};

export default Rank;
