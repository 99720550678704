import { fonts } from "theme/fonts";
import { colors } from "theme/colors";
import { addAlphaToHex } from "helpers/colors";

export const buttons = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 800,
        whiteSpace: "nowrap",
        fontFamily: fonts.header,
      },
      contained: {
        borderRadius: 0,
        background: addAlphaToHex(colors.white, 0.1),
        color: addAlphaToHex(colors.white, 0.8),
        boxShadow: "0px 0px 4px rgba(4, 6, 20, 0.38)",
        "&:hover": {
          background: colors.white,
          color: colors.black,
        },
      },
      containedSizeLarge: { padding: "12px 24px" },
      containedSizeMedium: {
        padding: "6px 18px",
      },
      outlined: {},
      text: {
        color: colors.white,
        borderRadius: 0,
        opacity: 0.5,
        transition: "0.2s all cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          opacity: 1,
        },
      },
      textSizeSmall: {
        padding: "4px 8px",
      },
      textSizeMedium: {},
    },
  },
};
