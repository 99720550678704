import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ width }) => ({
  img: {
    width: "100%",
    maxWidth: width,
  },
}));

const Avatar = ({ width }) => {
  return (
    <Wrapper width={width}>
      <img src="/images/players/avatar.png" alt="Player avatar" />
    </Wrapper>
  );
};

Avatar.propTypes = {
  width: PropTypes.number,
};

Avatar.defaultProps = {
  width: 300,
};

export default Avatar;
