import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import CustomLink from "components/atoms/CustomLink";
import React from "react";
import { fonts } from "theme/fonts";

const Text = styled("span")(({ theme }) => ({
  fontFamily: fonts.header,
  textTransform: "uppercase",
  fontSize: 16,
  padding: "4px 8px",
  fontWeight: 800,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

const JumpToLink = ({ id, text, color }) => {
  return (
    <CustomLink hashTo={`#${id}`}>
      <Stack sx={{ background: color }}>
        <Text>{text}</Text>
      </Stack>
    </CustomLink>
  );
};

export default JumpToLink;
