import React from "react";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import Icon from "../../../components/atoms/Icon";
import Players from "../molecules/Players";
import Title from "../atoms/Leaderboard/Title";
import Footer from "../molecules/Footer";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  gap: theme.spacing(2),
}));

const Leaderboard = ({ data, type }) => {
  if (!data) {
    return null;
  }

  return (
    <Wrapper id={type.id}>
      <Icon type={type.key} style={{ alignSelf: "center" }} />

      <Stack gap={1}>
        <Title title={data.title} />
        <Players players={data.players} color={data.color} />
      </Stack>
      <Footer type={type.key} />
    </Wrapper>
  );
};

Leaderboard.propTypes = {
  data: PropTypes.object,
};

Leaderboard.defaultProps = {
  data: {},
};

export default Leaderboard;
