import { TRIBALLY_API } from "config";

const leaderboards = `${TRIBALLY_API}/leaderboards`;
const graphql = "https://ime-pro.hasura.app/v1/graphql";

const constructPlayer = (name) => `${TRIBALLY_API}/player/${name}`;

const endpoints = {
  leaderboards,
  graphql,
  constructPlayer,
};

export default endpoints;
