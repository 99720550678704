import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Points from "../atoms/Player/Points";
import Name from "../atoms/Player/Name";
import Rank from "../atoms/Player/Rank";
import { addAlphaToHex } from "helpers/colors";
import CustomLink from "components/atoms/CustomLink";
import { createPlayerPageUrl } from "helpers/display";

const Wrapper = styled(Stack)(({ theme, color }) => ({
  alignItems: "center",
  height: 40,
  gridGap: theme.spacing(2),
  background: theme.palette.colors.glass.background,
  boxShadow: theme.palette.colors.glass.boxShadow,
  paddingRight: theme.spacing(1),
  transition: "0.2s transform ease",
  display: "grid",
  gridTemplateColumns: "40px 3fr 1.2fr",
  userSelect: "none",

  "&:hover": {
    transform: "scale(1.06)",
    background: addAlphaToHex(color, 0.2),
  },
}));

const Player = ({ name, points, rank, color }) => {
  return (
    <CustomLink to={createPlayerPageUrl(name)}>
      <Wrapper color={color}>
        <Rank rank={rank} color={color} />
        <Name name={name} />
        <Points points={points} />
      </Wrapper>
    </CustomLink>
  );
};

export default Player;
