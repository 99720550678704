import { put, takeLatest, retry, select } from "redux-saga/effects";
import * as types from "./actionTypes";
import * as actions from "./actions";
import api from "services/api";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

export function* fetchPlayer(action) {
  try {
    yield put(actions.fetchPlayerStarted());
    const players = yield select((state) => state.players.data);

    if (action.name in players) {
      yield put(actions.fetchPlayerSucceeded(players[action.name]));
    } else {
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestPlayer,
        action.name
      );
      yield put(actions.fetchPlayerSucceeded(data));
    }
  } catch (e) {
    yield put(actions.fetchPlayerFailed());
  }
}

export default function* playersSaga() {
  yield takeLatest(types.PLAYER_FETCH_PLAYER, fetchPlayer);
}
