import { put, takeLatest, retry, select } from "redux-saga/effects";
import * as types from "./actionTypes";
import * as actions from "./actions";
import api from "services/api";
import { LEADERBOARD_IS_STALE } from "config";
import moment from "moment";

const RETRY_TIMES = 3;
const RETRY_DELAY = 500;

const dataIsStale = (lastFetched) => {
  const now = moment().unix();

  if (!lastFetched || lastFetched < now - LEADERBOARD_IS_STALE) {
    return true;
  }
  return false;
};

export function* fetchLeaderboards(action) {
  try {
    const lastFetched = yield select((state) => state.leaderboards.lastFetched);

    if (dataIsStale(lastFetched)) {
      yield put(actions.fetchLeaderboardsStarted());
      const data = yield retry(
        RETRY_TIMES,
        RETRY_DELAY,
        api.requestLeaderboards
      );
      const now = moment().unix();
      yield put(actions.fetchLeaderboardsSucceeded(data, now));
    }
  } catch (e) {
    yield put(actions.fetchLeaderboardsFailed());
  }
}

export default function* leaderboardsSaga() {
  yield takeLatest(types.LEADERBOARDS_FETCH_LEADERBOARDS, fetchLeaderboards);
}
