import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Player from "./Player";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const Players = ({ players, color }) => {
  return (
    <Wrapper>
      {players.map((player, i) => (
        <Player {...player} color={color} key={`PLAYER_${i}`} />
      ))}
    </Wrapper>
  );
};

export default Players;
