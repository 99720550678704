import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { LEADERBOARDS } from "config";
import { keyframes } from "@mui/system";
import { addAlphaToHex } from "helpers/colors";

const Wrapper = styled(Stack)(({ width, style, color }) => ({
  alignItems: "center",
  img: {
    width,
    filter: `drop-shadow(0 0 8px ${addAlphaToHex(color, 0.7)})`,
    "&:hover": {
      animation: `${pulse(color)} ease 1s infinite`,
    },
  },

  ...style,
}));

const Icon = ({ type, width, style }) => {
  const { name, color, icon } = LEADERBOARDS[type];
  return (
    <Wrapper width={width} style={style} color={color}>
      <img src={icon} alt={`${name} leaderboard`} />
    </Wrapper>
  );
};

const pulse = (color) => keyframes`
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 8px ${addAlphaToHex(color, 0.7)});
  }
  70% {
    transform: scale(0.9);
    filter: drop-shadow(0 0 0px ${addAlphaToHex(color, 0.7)});
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 8px ${addAlphaToHex(color, 0.7)});
  }
`;

Icon.propTypes = {
  type: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.object,
};

Icon.defaultProps = {
  type: "resources",
  width: 80,
  style: {},
};

export default Icon;
