import * as types from "./actionTypes";

export const fetchPlayer = (name) => ({
  type: types.PLAYER_FETCH_PLAYER,
  name,
});

export const fetchPlayerStarted = () => ({
  type: types.PLAYER_FETCH_PLAYER_STARTED,
});

export const fetchPlayerFailed = () => ({
  type: types.PLAYER_FETCH_PLAYER_FAILED,
});

export const fetchPlayerSucceeded = (data) => ({
  type: types.PLAYER_FETCH_PLAYER_SUCCEEDED,
  data,
});
