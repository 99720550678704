import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Title from "./atoms/Title";
import Decoration from "./molecules/Decoration";
import Leaderboard from "./organisms/Leaderboard";
import { getLeaderboardsData } from "store/leaderboards/selectors";
import { updateControls, fetchLeaderboards } from "store/leaderboards/actions";
import SearchPlayer from "./atoms/Search";
import CustomLoader from "components/atoms/CustomLoader";
import Failed from "../../components/atoms/Failed";
import JumpToLinks from "./molecules/JumpToLinks";
import NotFound from "./molecules/NotFound";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  gap: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Leaderboards = ({
  data,
  loading,
  failed,
  controls,
  updateControls,
  fetchLeaderboards,
}) => {
  useEffect(() => {
    fetchLeaderboards();
  }, [fetchLeaderboards]);

  return (
    <Stack gap={{ xs: 8, sm: 8, md: 12 }}>
      <Stack gap={{ xs: 3, sm: 3, md: 5 }}>
        <Title />
        <SearchPlayer controls={controls} updateControls={updateControls} />
        <JumpToLinks links={data.types} />
      </Stack>

      {loading ? (
        <CustomLoader marginTop={100} marginBottom={100} />
      ) : failed ? (
        <Failed retry={fetchLeaderboards} />
      ) : data.numResults === 0 ? (
        <NotFound searchText={controls.searchText} />
      ) : (
        <Wrapper>
          {data.types.map((type, i) => (
            <Leaderboard data={data[type.key]} type={type} key={`LB_${i}`} />
          ))}
        </Wrapper>
      )}
      <Decoration />
    </Stack>
  );
};

const mapDispatchToProps = {
  updateControls,
  fetchLeaderboards,
};

const mapStateToProps = (state) => ({
  data: getLeaderboardsData(state),
  controls: state.leaderboards.controls,
  loading: state.leaderboards.loading,
  failed: state.leaderboards.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboards);
