import React from "react";
import { Stack } from "@mui/material";
import Failed from "components/atoms/Failed";
import Avatar from "../atoms/Avatar";
import BackToLeaderboard from "../atoms/BackToLeaderboard";

const NotFound = ({ fetchPlayer, name }) => {
  return (
    <Stack gap={4} alignItems="center">
      <Avatar />
      <Failed retry={() => fetchPlayer(name)} message="Player not found." />
      <BackToLeaderboard />
    </Stack>
  );
};

export default NotFound;
