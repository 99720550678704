import { palette } from "./palette";
import components from "./components";
import { fonts } from "./fonts";

export const makeTheme = () => ({
  typography: {
    fontFamily: fonts.body,
  },
  components,
  palette,
});
