import { createSelector } from "reselect";
import { displayPercent } from "helpers/display";
import { PLAYERS } from "config";

const getPlayers = (state) => {
  return state.players.data || {};
};

const getActivePlayerName = (state) => {
  return state.players.activePlayerName || "";
};

const calculateSuccessRate = (successful, total) => {
  if (total === 0) {
    return "-";
  }
  return displayPercent(successful / total);
};

export const getPlayerData = createSelector(
  [getPlayers, getActivePlayerName],
  (players, activePlayerName) => {
    if (!players[activePlayerName]) {
      return undefined;
    }

    const player = players[activePlayerName];

    const stats = PLAYERS.stats.map((stat) => ({
      label: stat.label,
      amount: player[stat.successful],
      successRate: calculateSuccessRate(
        player[stat.successful],
        player[stat.total]
      ),
    }));

    return {
      ...player,
      numSilverCredits: player.numSilverCredits
        ? player.numSilverCredits.toLocaleString()
        : undefined,
      stats,
    };
  }
);
