import { addAlphaToHex } from "helpers/colors";
import { fonts } from "theme/fonts";

export const inputs = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        transition: "0.2s all ease",
        borderRadius: 0,
        fontWeight: 500,
        backgroundColor: "transparent",
        "& .MuiOutlinedInput-notchedOutline": {
          transition: "0.2s all ease",
          border: `1px solid ${addAlphaToHex("#ffffff", 0.4)}`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "1px solid #fff",
          },
        "&:hover, &:active, &.Mui-focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active, &.Mui-focused": {
          backgroundColor: "transparent",
        },
      },
      input: {
        color: "#fff",
        fontFamily: fonts.header,
        "&:focus": {},
        "& ::placeholder": {
          fontWeight: 400,
          color: "#fff",
          opacity: 0.5,
        },
      },
    },
  },
};
