import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeTheme } from "./theme/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Routes from "./routes";

function App() {
  const theme = createTheme(makeTheme());
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
