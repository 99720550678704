import { fontSizes } from "theme/fontSizes";
import { fonts } from "../fonts";

export const typography = {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: fontSizes[80],
        lineHeight: 1.2,
        fontFamily: fonts.header,
        letterSpacing: 4,
        textTransform: "uppercase",
      },
      h2: {
        fontSize: fontSizes[64],
        lineHeight: 1.1,
        fontFamily: fonts.header,
        letterSpacing: 2,
        fontWeight: 800,
        textTransform: "uppercase",
      },
      h3: {
        fontSize: fontSizes[42],
        lineHeight: 1.3,
        fontFamily: fonts.header,
      },
      h4: {
        fontSize: fontSizes[30],
        lineHeight: 1.3,
        fontFamily: fonts.header,
      },
      body1: {
        lineHeight: 1.6,
        fontSize: fontSizes[20],
        fontWeight: 600,
        fontFamily: fonts.body,
      },
      body2: {
        lineHeight: 1.6,
        fontSize: fontSizes[16],
        fontWeight: 600,
        fontFamily: fonts.body,
      },
      span: {
        lineHeight: 1.4,
      },
    },
  },
};
