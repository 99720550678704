import { colors } from "./colors";

export const palette = {
  colors,
  primary: {
    main: colors.primary,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  text: {
    primary: colors.primary,
    secondary: colors.secondary,
    white: colors.white,
    black: colors.black,
  },
};
