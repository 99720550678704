import { LEADERBOARDS } from "config";
import { mapObjKeys } from "helpers/display";
import { createSelector } from "reselect";

const getLeaderboards = (state) => {
  return state.leaderboards.data || {};
};

const getControls = (state) => {
  return state.leaderboards.controls || {};
};

const filterPlayers = (players, txt, keyMapping) => {
  if (!players) {
    return undefined;
  }

  const key = Object.keys(keyMapping).find((k) => keyMapping[k] === "name");
  return players.filter((d) =>
    d[key].toLowerCase().includes(txt.toLowerCase())
  );
};

export const getLeaderboardsData = createSelector(
  [getLeaderboards, getControls],
  (leaderboards, controls) => {
    const types = [];
    const data = {};
    let numResults = 0;

    Object.keys(LEADERBOARDS).forEach((key) => {
      types.push({
        text: LEADERBOARDS[key].title,
        id: LEADERBOARDS[key].id,
        color: LEADERBOARDS[key].color,
        key,
      });

      const players =
        filterPlayers(
          leaderboards[LEADERBOARDS[key].dataKey],
          controls.searchText,
          LEADERBOARDS[key].keyMapping
        ) || [];

      numResults += players.length;

      data[key] = {
        title: LEADERBOARDS[key].title,
        color: LEADERBOARDS[key].color,
        icon: LEADERBOARDS[key].icon,
        players: players.map((l) =>
          mapObjKeys(l, LEADERBOARDS[key].keyMapping)
        ),
      };
    });

    return {
      ...data,
      numResults,
      types,
    };
  }
);
