import { Stack } from "@mui/material";
import React from "react";
import Avatar from "../atoms/Avatar";
import Name from "../atoms/Name";
import SilverCredits from "./SilverCredits";

const Header = ({ name, numSilverCredits }) => {
  return (
    <Stack gap={2} alignItems="center">
      <Avatar />
      <Name name={name} />
      <SilverCredits numSilverCredits={numSilverCredits} />
    </Stack>
  );
};

export default Header;
