import React from "react";
import { Stack } from "@mui/material";
import { RevolvingDot } from "react-loader-spinner";
import styled from "@emotion/styled";

const Wrapper = styled(Stack)(({ marginTop, marginBottom }) => ({
  alignItems: "center",
  marginTop,
  marginBottom,
}));

const CustomLoader = ({ marginTop, marginBottom }) => {
  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <RevolvingDot
        height="100"
        width="100"
        radius="6"
        color="#ffffff"
        ariaLabel="loading"
        visible={true}
      />
    </Wrapper>
  );
};

export default CustomLoader;
