import React from "react";
import Zector from "components/atoms/Zector";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(() => ({
  userSelect: "none",
  position: "absolute",
  bottom: 50,
  right: 50,
}));

const Decoration = () => {
  return (
    <Wrapper>
      <Zector id={258} />
    </Wrapper>
  );
};

export default Decoration;
