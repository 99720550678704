import React from "react";
import styled from "@emotion/styled";
import { TextField, Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 300,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SearchPlayer = ({ controls, updateControls }) => {
  const handleUpdateSearch = (e) => {
    updateControls({ ...controls, searchText: e.target.value });
  };

  return (
    <Wrapper>
      <TextField
        hiddenLabel
        variant="outlined"
        placeholder="Search display name"
        value={controls.searchText}
        onChange={handleUpdateSearch}
        sx={{ flexGrow: 1 }}
        autoComplete="off"
        spellCheck="false"
      />
    </Wrapper>
  );
};

export default SearchPlayer;
