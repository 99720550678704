import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography, Button } from "@mui/material";

const Failed = ({ retry, message }) => {
  return (
    <Stack gap={2} sx={{ alignItems: "center", pt: "100px" }}>
      <Typography variant="span" fontSize={20}>
        {message}
      </Typography>
      <Button variant="contained" size="large" onClick={retry}>
        Try Again
      </Button>
    </Stack>
  );
};

Failed.propTypes = {
  retry: PropTypes.func,
  message: PropTypes.string,
};

Failed.defaultProps = {
  retry: () => {},
  message: "Something went wrong.",
};

export default Failed;
