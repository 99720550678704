import React from "react";
import styled from "@emotion/styled";
import { Typography, Stack } from "@mui/material";
import { fonts } from "theme/fonts";

const Wrapper = styled(Stack)(() => ({
  maxWidth: 500,
  textAlign: "center",
  userSelect: "none",

  ".MuiTypography-h1": {
    fontFamily: fonts.body,
    fontSize: 60,
  },
}));

const Name = ({ name }) => {
  return (
    <Wrapper>
      <Typography variant="h1">{name}</Typography>
    </Wrapper>
  );
};

export default Name;
