import * as types from "./actionTypes";

export const fetchLeaderboards = () => ({
  type: types.LEADERBOARDS_FETCH_LEADERBOARDS,
});

export const fetchLeaderboardsStarted = () => ({
  type: types.LEADERBOARDS_FETCH_LEADERBOARDS_STARTED,
});

export const fetchLeaderboardsFailed = () => ({
  type: types.LEADERBOARDS_FETCH_LEADERBOARDS_FAILED,
});

export const fetchLeaderboardsSucceeded = (data, timestamp) => ({
  type: types.LEADERBOARDS_FETCH_LEADERBOARDS_SUCCEEDED,
  data,
  timestamp,
});

export const updateControls = (controls) => ({
  type: types.LEADERBOARDS_UPDATE_CONTROLS,
  controls,
});
