export const MOCK_MODE = false;
export const TRIBALLY_SLOGAN = "Powered By Tribally Games";

// export const TRIBALLY_API = `https://${process.env.REACT_APP_API_HOSTNAME}`
export const TRIBALLY_API = `https://tribally.xyz/imperium-empires`;
// export const TRIBALLY_API = `http://localhost:3001/imperium-empires`;

export const LEADERBOARD_IS_STALE = 5 * 60 * 60;
export const LEADERBOARDS = {
  resources: {
    title: "Resources",
    color: "#D38C4B",
    icon: "/images/icons/resource.png",
    dataKey: "resourcesLeaderboard",
    id: "resources",
    keyMapping: {
      publicName: "name",
      numSuccessfulResources: "points",
      resourceRank: "rank",
    },
  },
  lurks: {
    title: "Lurks",
    color: "#7C00B7",
    icon: "/images/icons/lurk.png",
    dataKey: "lurksLeaderboard",
    id: "lurks",
    keyMapping: {
      publicName: "name",
      numSuccessfulLurks: "points",
      lurkRank: "rank",
    },
  },
  silverCredits: {
    title: "Silver Credits",
    color: "#1D3C8C",
    icon: "/images/icons/silver-credit.png",
    dataKey: "silverCreditsLeaderboard",
    id: "silver-credits",
    keyMapping: {
      publicName: "name",
      numSilverCredits: "points",
      scRank: "rank",
    },
  },
};

export const PLAYERS = {
  stats: [
    {
      successful: "numSuccessfulMines",
      total: "numMines",
      label: "Mines",
    },
    {
      successful: "numSuccessfulLurks",
      total: "numLurks",
      label: "Lurks",
    },
    {
      successful: "numSuccessfulTrades",
      total: "numTrades",
      label: "Trades",
    },
    {
      successful: "numSuccessfulRefines",
      total: "numRefines",
      label: "Refines",
    },
  ],
};
