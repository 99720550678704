import React from "react";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.colors.black,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid rgba(255, 255, 255, 0.19)",
}));

const Title = ({ title }) => {
  return (
    <Wrapper>
      <Typography variant="h2" fontSize={20}>
        {title}
      </Typography>
    </Wrapper>
  );
};

export default Title;
