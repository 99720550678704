import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Stat from "../molecules/Stat";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
  flexDirection: "row",
  gap: theme.spacing(8),
  alignItems: "center",
  userSelect: "none",
  background: theme.palette.colors.glass.background,
  boxShadow: theme.palette.colors.glass.boxShadow,
  [theme.breakpoints.down("md")]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(4),
    padding: `${theme.spacing(3)} ${theme.spacing(8)}`,
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    width: "100%",
  },
}));

const Stats = ({ stats }) => {
  return (
    <Wrapper>
      {stats.map((stat, i) => (
        <Stat {...stat} key={`STAT_${i}`} />
      ))}
    </Wrapper>
  );
};

export default Stats;
