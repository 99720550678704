import React from "react";
import { Typography } from "@mui/material";
import { fonts } from "theme/fonts";

const StatAmount = ({ children }) => {
  return (
    <Typography variant="span" fontFamily={fonts.header} fontSize={48}>
      {children}
    </Typography>
  );
};

export default StatAmount;
