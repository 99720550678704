const neutral = {};

const main = {
  white: "#ffffff",
  black: "#0B0B0B",
  primary: "#51EF90",
  secondary: "#000000",
  glass: {
    background: "rgba(255, 255, 255, 0.05)",
    boxShadow: "0px 0px 4px rgba(4, 6, 20, 0.38)",
  },
};

const nav = {};

const branding = {
  imperiumEmpires: "#3AC9D6",
};

const boxShadows = {};

export const colors = {
  ...main,
  ...branding,
  nav,
  neutral,
  boxShadows,
  background: main.black,
};
