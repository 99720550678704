import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import React from "react";
import CustomLink from "../CustomLink";

const Wrapper = styled(Stack)(() => ({
  img: {
    width: 200,
  },
}));

const Logo = () => {
  return (
    <CustomLink to="/">
      <Wrapper>
        <img src="/images/logo.png" alt="Imperium Empires Tech" />
      </Wrapper>
    </CustomLink>
  );
};

export default Logo;
