import { combineReducers } from "redux";
import leaderboardsReducer from "./leaderboards/reducer";
import playersReducer from "./players/reducer";

const rootReducer = combineReducers({
  leaderboards: leaderboardsReducer,
  players: playersReducer,
});

export default rootReducer;
