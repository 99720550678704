import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import Header from "../molecules/Header";
import Stats from "./Stats";
import BackToLeaderboard from "../atoms/BackToLeaderboard";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: theme.spacing(4),
}));

const Profile = ({ player }) => {
  return (
    <Wrapper>
      <Header
        name={player.publicName}
        numSilverCredits={player.numSilverCredits}
      />
      <Stats stats={player.stats} />
      <BackToLeaderboard />
    </Wrapper>
  );
};

export default Profile;
