import React, { useEffect } from "react";
import { connect } from "react-redux";
import Profile from "./organisms/Profile";
import CustomLoader from "components/atoms/CustomLoader";
import { getPlayerData } from "store/players/selectors";
import { fetchPlayer } from "store/players/actions";
import { useParams } from "react-router-dom";
import NotFound from "./molecules/NotFound";

const Player = ({ player, loading, failed, fetchPlayer }) => {
  const { name } = useParams();

  useEffect(() => {
    if (name != null || name !== "") {
      fetchPlayer(name);
    }
  }, [name, fetchPlayer]);

  return loading ? (
    <CustomLoader marginTop={100} marginBottom={100} />
  ) : failed || player == null ? (
    <NotFound fetchPlayer={fetchPlayer} name={name} />
  ) : (
    <Profile player={player} />
  );
};

const mapDispatchToProps = {
  fetchPlayer,
};

const mapStateToProps = (state) => ({
  player: getPlayerData(state),
  loading: state.players.loading,
  failed: state.players.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
