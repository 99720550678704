import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "@emotion/styled";
import SEO from "routes/SEO";

const Wrapper = styled(Box)(() => ({
  backgroundImage: "url('/images/accents/background.jpg')",
  backgroundRepeat: "repeat-y",
  minHeight: "100vh",
  position: "relative",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  img: {
    width: "100%",
    maxWidth: 400,
  },
}));

const ComingSoon = () => {
  return (
    <Wrapper>
      <SEO />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh" }}
      >
        <img src="/images/logo.png" alt="Imperium Empires Tech" />
      </Stack>
    </Wrapper>
  );
};

export default ComingSoon;
