import * as types from "./actionTypes";
import produce from "immer";

const initialState = {
  controls: {
    searchText: "",
  },
  data: {},
};

export const leaderboardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.LEADERBOARDS_FETCH_LEADERBOARDS:
        draft.controls = {
          searchText: "",
        };
        break;

      case types.LEADERBOARDS_FETCH_LEADERBOARDS_STARTED:
        draft.loading = true;
        draft.failed = false;
        break;

      case types.LEADERBOARDS_FETCH_LEADERBOARDS_SUCCEEDED:
        draft.loading = false;
        draft.data = action.data;
        draft.lastFetched = action.timestamp;
        break;

      case types.LEADERBOARDS_FETCH_LEADERBOARDS_FAILED:
        draft.loading = false;
        draft.failed = true;
        break;

      case types.LEADERBOARDS_UPDATE_CONTROLS:
        draft.controls = action.controls;
        break;

      default:
        break;
    }
  });

export default leaderboardsReducer;
