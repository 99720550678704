import endpoints from "./endpoints";
import { MOCK_MODE } from "config";
import DUMMY from "data/dummy";

const requestLeaderboards = async () => {
  const response = await fetch(endpoints.leaderboards);
  const data = await response.json();
  return data;
};

const requestPlayer = async (name) => {
  const response = await fetch(endpoints.constructPlayer(name));
  const data = await response.json();
  return data;
};

const realApi = {
  requestLeaderboards,
  requestPlayer,
};

const dummyApi = {
  requestLeaderboards: () => DUMMY.LEADERBOARDS,
  requestPlayer: () => DUMMY.PROFILE,
};

const api = MOCK_MODE ? dummyApi : realApi;

export default api;
