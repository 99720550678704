export const seo = {
  title: "Imperium Empires Tech",
  description: "The #1 community tooling site for Imperium Empires.",
  url: "https://imperiumempires.tech",
  twitter: "@TriballyGames",
  keywords:
    "imperium empires, tribally, nft, nft gaming, space metaverse, imperium metaverse, leaderboard, apollo, resources, mines, lurks, silver credits, aaa gamefi, $IME",
  twitterImage: "https://imperiumempires.tech/images/social/twitter.jpg",
  ogImage: "https://imperiumempires.tech/images/social/facebook.jpg",
};

const augmentTitle = (title) => `${title} | ${seo.title}`;

export const pageSeo = {
  leaderboards: {
    title: augmentTitle("Leaderboards"),
    description: seo.description,
  },
  player: {
    title: augmentTitle("Player Profile"),
    description: seo.description,
  },
  termsAndConditions: {
    title: augmentTitle("Terms and Conditions"),
    description: seo.description,
  },
  privacyPolicy: {
    title: augmentTitle("Privacy Policy"),
    description: seo.description,
  },
};
