import Player from "./Player";
import Leaderboards from "./Leaderboards";
import ComingSoon from "./ComingSoon";
import TermsAndConditions from "./About/TermsAndConditions";
import PrivacyPolicy from "./About/PrivacyPolicy";

const pages = {
  Player,
  Leaderboards,
  ComingSoon,
  TermsAndConditions,
  PrivacyPolicy,
};

export default pages;
