import styled from "@emotion/styled";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import JumpToLink from "../atoms/JumpToLink";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.colors.black,
  alignItems: "center",
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 99,
  padding: `${theme.spacing(2)} 0`,
}));

const Links = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  justifyContent: "center",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
  },
}));

const JumpToLinks = ({ links }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  if (!mdDown) {
    return null;
  }

  return (
    <Wrapper>
      <Links>
        {links.map((link, i) => (
          <JumpToLink {...link} key={`LINK_${i}`} />
        ))}
      </Links>
    </Wrapper>
  );
};

export default JumpToLinks;
