import React from "react";
import styled from "@emotion/styled";
import { Typography, Stack } from "@mui/material";
import { LEADERBOARDS } from "config";
import { fonts } from "theme/fonts";
import Icon from "components/atoms/Icon";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: LEADERBOARDS.silverCredits.color,
  borderRadius: theme.spacing(8),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  fontSize: 22,
  fontFamily: fonts.header,
  flexDirection: "row",
  gap: theme.spacing(1),
  alignItems: "center",
  userSelect: "none",
}));

const SilverCredits = ({ numSilverCredits }) => {
  return (
    <Wrapper>
      <Icon type="silverCredits" width={32} />
      {numSilverCredits != null && (
        <Typography variant="span">{numSilverCredits}</Typography>
      )}
    </Wrapper>
  );
};

export default SilverCredits;
