import React from "react";
import { Typography } from "@mui/material";

const Name = ({ name }) => {
  return (
    <Typography
      variant="span"
      fontSize={18}
      fontWeight={600}
      color="text.white"
      sx={{
        display: "block",
        letterSpacing: 1,
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {name}
    </Typography>
  );
};

export default Name;
