import { fonts } from "theme/fonts";
import { colors } from "theme/colors";

export const tooltip = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        background: colors.black,
        color: colors.white,
        fontWeight: 600,
        fontFamily: fonts.header,
      },
    },
  },
};
