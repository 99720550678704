import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import CustomLink from "components/atoms/CustomLink";
import { fontSizes } from "theme/fontSizes";
import { fonts } from "theme/fonts";
import TriballyBadge from "components/atoms/TriballyBadge/TriballyBadge";
import { TRIBALLY_SLOGAN } from "config";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "center",
  fontSize: fontSizes[12],
  textTransform: "uppercase",
  fontFamily: fonts.header,
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  flexWrap: "wrap",

  a: {
    opacity: 0.3,
    transition: "0.2s opacity ease",
  },

  "& a:hover": {
    opacity: 1,
  },
}));

const Footer = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ paddingBottom: 8 }}
    >
      <Wrapper>
        <CustomLink to="/terms-and-conditions">
          <span>Terms and Conditions</span>
        </CustomLink>
        <CustomLink to="/privacy-policy">
          <span>Privacy Policy</span>
        </CustomLink>
      </Wrapper>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="Center"
      >
        <TriballyBadge
          width={250}
          title={TRIBALLY_SLOGAN}
          alt={TRIBALLY_SLOGAN}
        />
      </Stack>
    </Stack>
  );
};

export default Footer;
