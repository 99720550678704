import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const Wrapper = styled(Stack)(({ width }) => ({
  img: {
    width,
  },
}));

const Zector = ({ id, width }) => {
  return (
    <Wrapper width={width}>
      <img src={`/images/game/zectors/${id}.png`} alt={`Zector ${id}`} />
    </Wrapper>
  );
};

Zector.propTypes = {
  id: PropTypes.number,
  width: PropTypes.number,
};

Zector.defaultProps = {
  id: 258,
  width: 180,
};

export default Zector;
