import { colors } from "../colors";

export const baseline = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        background: colors.background,
        color: colors.white,
      },
    },
  },
};
