export const LEADERBOARDS_FETCH_LEADERBOARDS =
  "LEADERBOARDS_FETCH_LEADERBOARDS";
export const LEADERBOARDS_FETCH_LEADERBOARDS_STARTED =
  "LEADERBOARDS_FETCH_LEADERBOARDS_STARTED";
export const LEADERBOARDS_FETCH_LEADERBOARDS_FAILED =
  "LEADERBOARDS_FETCH_LEADERBOARDS_FAILED";
export const LEADERBOARDS_FETCH_LEADERBOARDS_SUCCEEDED =
  "LEADERBOARDS_FETCH_LEADERBOARDS_SUCCEEDED";

export const LEADERBOARDS_UPDATE_CONTROLS = "LEADERBOARDS_UPDATE_CONTROLS";
