import { Typography } from "@mui/material";
import React from "react";
import { fonts } from "theme/fonts";

const Points = ({ points }) => {
  return (
    <Typography
      variant="span"
      fontSize={14}
      fontFamily={fonts.monospace}
      fontWeight={500}
      color="text.white"
      textAlign="right"
    >
      {points}
    </Typography>
  );
};

export default Points;
