import React from "react";
import { Typography } from "@mui/material";
import { fonts } from "theme/fonts";

const StatLabel = ({ children }) => {
  return (
    <Typography
      variant="span"
      fontFamily={fonts.header}
      fontSize={22}
      sx={{ textTransform: "uppercase" }}
    >
      {children}
    </Typography>
  );
};

export default StatLabel;
