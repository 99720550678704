import React from "react";
import { Button } from "@mui/material";
import { CgArrowLongLeftR } from "react-icons/cg";
import CustomLink from "components/atoms/CustomLink";

const BackToLeaderboard = () => {
  return (
    <CustomLink to="/">
      <Button variant="text" size="small" startIcon={<CgArrowLongLeftR />}>
        Back to Leaderboard
      </Button>
    </CustomLink>
  );
};

export default BackToLeaderboard;
