import React from "react";
import { Routes as RRoutes, Route } from "react-router";
import SitePage from "./SitePage";
import pages from "pages";

const Routes = () => {
  return (
    <RRoutes>
      {/* <Route exact path="*" element={<pages.ComingSoon />} /> */}
      <Route
        exact
        path="/"
        element={<SitePage page={<pages.Leaderboards />} seo="leaderboards" />}
      />
      <Route
        exact
        path="/player/:name"
        element={<SitePage page={<pages.Player />} seo="player" />}
      />
      <Route
        exact
        path="/player/"
        element={<SitePage page={<pages.Leaderboards />} seo="leaderboards" />}
      />
      <Route
        exact
        path="/terms-and-conditions"
        element={<SitePage page={<pages.TermsAndConditions />} seo="termsAndConditions" />}
      />
      <Route
        exact
        path="/privacy-policy"
        element={<SitePage page={<pages.PrivacyPolicy />} seo="privacyPolicy" />}
      />
    </RRoutes>
  );
};

export default Routes;
