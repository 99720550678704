import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import StatLabel from "../atoms/StatLabel";
import StatAmount from "../atoms/StatAmount";
import StatSuccessRate from "../atoms/StatSuccessRate";

const Wrapper = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: theme.spacing(1),
}));

const Stat = ({ amount, label, successRate }) => {
  return (
    <Wrapper>
      <Stack sx={{ mb: -2 }}>
        <StatAmount>{amount}</StatAmount>
      </Stack>
      <StatLabel>{label}</StatLabel>
      <StatSuccessRate>{successRate}</StatSuccessRate>
    </Wrapper>
  );
};

export default Stat;
