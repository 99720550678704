import { typography } from "./typography";
import { baseline } from "./baseline";
import { buttons } from "./buttons";
import { links } from "./links";
import { inputs } from "./inputs";
import { tooltip } from "./tooltip";

const components = {
  ...baseline,
  ...typography,
  ...buttons,
  ...links,
  ...inputs,
  ...tooltip,
};

export default components;
