import React from "react";
import PropTypes from "prop-types";
import Nav from "components/organisms/Nav";
import SEO from "./SEO";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import Page from "components/atoms/Page";
import Footer from "components/organisms/Footer";

const Wrapper = styled(Box)(() => ({
  backgroundImage: "url('/images/accents/background.jpg')",
  backgroundRepeat: "repeat-y",
  minHeight: "100vh",
  position: "relative",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
}));

const SitePage = ({ page, seo }) => {
  return (
    <Wrapper>
      <Container>
        <SEO seoProp={seo} />
        <Nav />
        <Page>{page}</Page>
        <Footer />
      </Container>
    </Wrapper>
  );
};

SitePage.propTypes = {
  page: PropTypes.element,
  seo: PropTypes.string,
};

SitePage.defaultProps = {
  page: undefined,
  seo: undefined,
};

export default SitePage;
