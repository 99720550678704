import React from "react";
import styled from "@emotion/styled";
import { Stack, Button, Typography } from "@mui/material";
import CustomLink from "components/atoms/CustomLink";
import { createPlayerPageUrl } from "helpers/display";

const Wrapper = styled(Stack)(({ theme }) => ({
  width: 440,
  margin: "0 auto",
  gap: theme.spacing(2),
  alignItems: "center",
  paddingTop: "100px",
}));

const NotFound = ({ searchText }) => {
  return (
    <Wrapper>
      <Typography variant="span" fontSize={20}>
        Player not in top 200.
      </Typography>
      <CustomLink to={createPlayerPageUrl(searchText)}>
        <Button size="large" variant="contained">
          Lookup Player
        </Button>
      </CustomLink>
    </Wrapper>
  );
};

export default NotFound;
