export const links = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: "#fff",
        textDecoration: "none",
        "&:hover": {
          cursor: "pointer",
        },
        "&.MuiLink-underlineAlways": {},
      },
    },
  },
};
