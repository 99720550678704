import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import React from "react";
import Icon from "../../../components/atoms/Icon";

const WhiteLine = styled(Box)(() => ({
  height: 2,
  width: 80,
  backgroundColor: "#fff",
}));

const Wrapper = styled(Stack)(() => ({
  alignItems: "center",
  flexDirection: "row",
}));

const OuterWrapper = styled(Wrapper)(() => ({
  justifyContent: "space-between",
  flexWrap: "wrap",
}));

const Footer = ({ type }) => {
  return (
    <OuterWrapper>
      <Wrapper>
        <WhiteLine />
        <Icon width={22} type={type} />
      </Wrapper>

      <Wrapper>
        <Icon width={22} type={type} />
        <WhiteLine />
      </Wrapper>
    </OuterWrapper>
  );
};

export default Footer;
