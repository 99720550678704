import React from "react";
import styled from "@emotion/styled";
import { Stack, Tooltip, Typography } from "@mui/material";
import { fonts } from "theme/fonts";

const Wrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.colors.black,
  padding: theme.spacing(1),
  minWidth: 65,
  textAlign: "center",
  cursor: "pointer",
}));

const StatSuccessRate = ({ children }) => {
  return (
    <Tooltip title={children === "-" ? "" : `${children} success rate`}>
      <Wrapper>
        <Typography
          variant="span"
          color="text.primary"
          fontFamily={fonts.header}
        >
          {children}
        </Typography>
      </Wrapper>
    </Tooltip>
  );
};

export default StatSuccessRate;
